.keyboard {
    max-width: 630px;
    padding: 15px 15px;
}

.letter {
    border: 3px solid gold;
    padding: 2px;

    text-transform: capitalize;
}