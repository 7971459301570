@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    overflow: hidden;
    position: relative;
    min-height: 100%;
    background-color: #3A3967 !important;

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body {
    background-color: #3A3967 !important;
    overflow: auto;
    position: relative;
}

html, body {
    height: 100%;
    min-height: 100%;
    max-width: 100%;
    overflow-x: hidden;
    -webkit-backface-visibility: hidden;
}

:focus {
    outline: none !important;
}

.vkuiInternalPanel__in {
    background-color: #3A3967 !important;
}

.vkuiCard {
    background-color: #F5EFF1 !important;
}

.vkuiPanelHeader__in {
    background-color: #3A3967 !important;
}

.vkuiPanelHeader__content-in, .vkuiPanelHeader__content {
    @apply !text-gray-300;
}

.vkuiModalCardBase__header {
    @apply !text-gray-800;
}

.vkuiSeparator__in {
    @apply !bg-gray-500/30;
}
